import { breakpoints, css } from 'src/styles/theme';
import { rem } from 'polished';

export const StylesOV = css`
    .title {
        font-size: ${({ theme }) => theme.fontSizes.h2};
        padding-left: ${rem(10)};
    }

    .filters {
        color: ${({ theme }) => theme.colors.black2};
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
    }

    .switcher {
        &-switch {
            .slider {
                background-color: ${({ theme }) => theme.colors.black2};
            }
        }
    }
`;
