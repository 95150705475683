import { breakpoints, css } from '../../styles/theme';
import { rem } from 'polished';

export const StylesDS = css`
    .title {
        font-size: ${({ theme }) => theme.fontSizes.h2};
        padding-left: ${rem(10)};
    }

    .filters {
        color: ${({ theme }) => theme.colors.black};
    }
`;
