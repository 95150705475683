import { breakpoints, css } from '../../styles/theme';
import { rem } from 'polished';

export const StylesAC = css`
    .title {
        font-size: ${({ theme }) => theme.fontSizes.h2};
    }

    .switcher {
        &-switch {
            .slider {
                background-color: ${({ theme }) => theme.colors.blue2};
            }
        }
    }

    svg {
        path {
            fill: ${({ theme }) => theme.colors.grey5} !important;
        }
    }
`;
