import styled from 'styled-components';
import { breakpoints, SC } from '../../styles/theme';
import FilterBoxTemplate from './FilterBoxTemplate';
import { rem } from 'polished';
import { FILTER_CATEGORY, isBrandAC, isBrandAP, isBrandDS, isBrandOV, isMarketGB } from '../../constants/main';
import { StylesOV } from './Styles.OV';
import { StylesOVGB } from './Styles.OVGB';
import { StylesDS } from './Styles.DS';
import { StylesAP } from './Styles.AP';
import { StylesAC } from './Styles.AC';

export interface FilterBoxProps extends SC {
    openFilter: (category?: FILTER_CATEGORY) => void;
}

export const FilterBox = styled(FilterBoxTemplate)`
    background: ${({ theme }) => theme.colors.white};
    padding: ${rem(16)};
    margin-bottom: ${rem(10)};
    position: sticky;
    top: ${({ isUserLoggedInAsDealer }) => (isUserLoggedInAsDealer ? rem(36) : 0)};
    z-index: 99999;
    width: 100%;

    .wrapper {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        display: flex;

        @media only screen and (min-width: 75em) {
            max-width: 92rem !important;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${rem(32)};
        font-size: ${({ theme }) => theme.fontSizes.h4};
        width: 100%;

        @media (min-width: ${breakpoints.sm}px) {
            width: auto;
            justify-content: flex-start;
            padding-right: ${rem(40)};
            border-right: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        gap: ${rem(20)};
        flex-wrap: wrap;
    }

    .switcher {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        height: ${rem(32)};
        display: none;
        align-items: center;
        margin-left: ${rem(5)};
        padding-left: ${rem(30)};

        @media (min-width: ${breakpoints.sm}px) {
            display: flex;
        }

        &-label {

            &.isActive {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
            }
        }

        &-switch {
            position: relative;
            display: inline-block;
            width: ${rem(42)};
            height: ${rem(22)};
            margin: 0 ${rem(15)};

            input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: ${({ theme }) => theme.colors.primary};
                -webkit-transition: 0.4s;
                transition: 0.4s;
            }

            .slider:before {
                position: absolute;
                content: '';
                height: ${rem(15)};
                width: ${rem(15)};
                left: 8%;
                bottom: ${rem(4)};
                background-color: ${({ theme }) => theme.colors.white};
            }

            input:checked + .slider:before {
                transform: translateX(20px);
            }

            .slider.round {
                border-radius: ${rem(35)};
            }

            .slider.round:before {
                border-radius: 50%;
            }
        }
    }

    ${isBrandAC && StylesAC};
    ${isBrandAP && StylesAP};
    ${isBrandDS && StylesDS};
    ${isBrandOV && (isMarketGB ? StylesOVGB : StylesOV)}
`;

export default FilterBox;
