import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterBoxProps } from './index';
import { FILTER_CATEGORY, ModalVersion } from '../../constants/main';
import { useTranslations } from '@hooks/useTranslations';
import { HandledComponentError } from '@components/ErrorHandling';
import { Redux } from '../../redux/redux.interface';
import FilterDuck from '../../redux/filters/filter.duck';
import { useMoneyFormatter } from '@hooks/useMoneyFormatter';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import { useRouter } from 'next/router';
import Routes from '../../constants/routes';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { SEARCH_TYPES } from '@components/Modal/ModalPostalCode/ModalPostalCodeTemplate';
import DealerDuck from '../../redux/dealer/dealer.duck';
import { IconTemplate } from '@components/Icon/IconTemplate';
import { Icons } from '@components/Icon';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../context/featureSwitchApp';
import { getModelTitle } from '../../services/carList/utils/Car.utils';
import TrimSelectorDuck from '../../redux/trimSelector/trimSelector.duck';
import { modifyFilters } from '../../services/filters/utils/Filters.utils';
import { STOCK_TYPE } from '../../services/filters/filters';
import { StockType } from '../../services/stock/models/stockItems.service.model';
import FilterChip from '../../ui/FilterChip';
import { CarJourneyType } from '../../services';

const MAX_LENGTH_OF_SELECTED_FILTER_NAME = 20;

const FilterBoxTemplate: FC<FilterBoxProps> = ({ className, openFilter }) => {
    try {
        const { t } = useTranslations();

        const { formatMoney } = useMoneyFormatter();

        const { isStockJourney } = useCarStockJourneyCheck();

        const router = useRouter();

        const dispatch = useDispatch();

        const filters = useSelector((state: Redux) => FilterDuck.getFilters(state));

        const geoLocation = useSelector((state: Redux) => FilterDuck.getGeoLocation(state));
        const distanceRadius = useSelector((state: Redux) => FilterDuck.getDistanceRadius(state));
        const geoLocationName = useSelector((state: Redux) => FilterDuck.getGeoLocationName(state));

        const filteredStockTrims = useSelector((state: Redux) => TrimSelectorDuck.getFilteredStockTrims(state));

        const selectedDealer = useSelector((state: Redux) => DealerDuck.getOwnState(state).dealer);

        const allFilters = useSelector((state: Redux) => FilterDuck.getFilters(state));

        const selectedFilters = useMemo(() => allFilters.filter((filter) => !!filter.value), [allFilters]);

        const selectedPrices = useMemo(
            () => selectedFilters.filter((filter) => filter.parent === FILTER_CATEGORY.PRICES),
            [selectedFilters]
        );

        const selectedVehicleType = useMemo(
            () =>
                selectedFilters.filter((filter) =>
                    [FILTER_CATEGORY.VEHICLE_TYPE].includes(filter.parent as FILTER_CATEGORY)
                ),
            [selectedFilters]
        );

        const selectedFuel = useMemo(
            () =>
                selectedFilters.filter((filter) =>
                    [FILTER_CATEGORY.FUEL, FILTER_CATEGORY.ENERGIES].includes(filter.parent as FILTER_CATEGORY)
                ),
            [selectedFilters]
        );

        const selectedGearbox = useMemo(
            () =>
                selectedFilters.filter((filter) =>
                    [FILTER_CATEGORY.GEARBOX, FILTER_CATEGORY.GEARBOX_TYPES].includes(filter.parent as FILTER_CATEGORY)
                ),
            [selectedFilters]
        );

        const selectedColors = useMemo(
            () => selectedFilters.filter((filter) => filter.parent === FILTER_CATEGORY.COLOR_GROUPS),
            [selectedFilters]
        );

        const selectedEngines = useMemo(
            () => selectedFilters.filter((filter) => (filter.parent as FILTER_CATEGORY) === FILTER_CATEGORY.ENGINES),
            [selectedFilters]
        );

        const selectedGrades = useMemo(
            () => selectedFilters.filter((filter) => (filter.parent as FILTER_CATEGORY) === FILTER_CATEGORY.GRADES),
            [selectedFilters]
        );

        const formattedDealerName = selectedDealer
            ? selectedDealer?.name?.length > MAX_LENGTH_OF_SELECTED_FILTER_NAME
                ? `${selectedDealer.name.substring(0, MAX_LENGTH_OF_SELECTED_FILTER_NAME)}...`
                : selectedDealer?.name
            : '';

        const formattedLocationName = geoLocationName
            ? geoLocationName?.length > MAX_LENGTH_OF_SELECTED_FILTER_NAME
                ? `${geoLocationName.substring(0, MAX_LENGTH_OF_SELECTED_FILTER_NAME)}...`
                : geoLocationName
            : '';

        const formattedDistance = `${distanceRadius} ${t('geoLocationInput.distanceRadiusOption')}`;

        const isTrimPage = router.pathname.includes(Routes.SELECTOR);

        const getVehicleTitle = () => {
            if (!!filteredStockTrims.length) {
                const shouldIncludeBodyStyleDescription = useFeatureSwitchEnabled(
                    FEATURES_LIST.FEATURE_SWITCH_STOCK_SHOW_GR_BODY_STYLE_IN_CAR_TITLE
                );

                return getModelTitle(filteredStockTrims[0], shouldIncludeBodyStyleDescription);
            }

            return '';
        };

        const areAvailableDemoVehiclesForTrimPage = useSelector(
            (state: Redux) => TrimSelectorDuck.getOwnState(state).stock.areAvailableDemoTrims
        );
        const isDemoVehicleFilterEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_DEMO_VEHICLES_ENABLED);

        return (
            <div className={className}>
                <div className="wrapper">
                    <div className="selected">
                        <div className="buttons">
                            {isTrimPage && <div className="title">{getVehicleTitle()}</div>}
                            {isStockJourney && (
                                <FilterChip
                                    iconName={Icons.Location}
                                    customClassName="withBorder"
                                    label={`
                                    ${selectedDealer?.externalId ? formattedDealerName : ''}
                                    ${
                                        !selectedDealer?.externalId &&
                                        formattedLocationName &&
                                        !formattedLocationName?.includes(String(NaN)) &&
                                        !formattedLocationName?.includes('undefined')
                                            ? `${t('GeoLocationInput.prefix')} ${formattedLocationName}${
                                                  distanceRadius > 0 ? `, ${formattedDistance}` : ''
                                              }`
                                            : t('GeoLocationInput.label')
                                    }
                                `}
                                    onClick={() =>
                                        dispatch(
                                            UIDuck.openModal({
                                                data: {
                                                    searchType: selectedDealer?.externalId
                                                        ? SEARCH_TYPES.DEALER
                                                        : SEARCH_TYPES.LOCATION,
                                                },
                                                callbacks: {},
                                                modalType: UIDuck.MODAL_TYPES.POSTAL_CODE,
                                                modalVersion: ModalVersion.v2,
                                                modalProperties: {
                                                    centerModeOnDesktop: true,
                                                },
                                            })
                                        )
                                    }
                                />
                            )}
                            <FilterChip
                                iconName={Icons.Filter}
                                label={t('filters.filters')}
                                countOfSelectedFilters={selectedFilters?.length}
                                onClick={() => openFilter()}
                            />
                            {isStockJourney &&
                                isDemoVehicleFilterEnabled &&
                                (router.pathname === `/${CarJourneyType.STOCK}` ||
                                    router.pathname === `/${CarJourneyType.CONFIGURABLE}` ||
                                    areAvailableDemoVehiclesForTrimPage) && (
                                    <div className="switcher">
                                        <span
                                            className={`switcher-label ${
                                                !router?.query[STOCK_TYPE] || router?.query[STOCK_TYPE] === StockType.VN
                                                    ? 'isActive'
                                                    : ''
                                            }`}
                                        >
                                            {t('filters.newVehicles')}
                                        </span>
                                        <label className="switcher-switch">
                                            <input
                                                type="checkbox"
                                                checked={router?.query[STOCK_TYPE] === StockType.VD}
                                                onClick={() => {
                                                    const newFilters = modifyFilters(
                                                        filters,
                                                        STOCK_TYPE,
                                                        router?.query[STOCK_TYPE] === StockType.VD
                                                            ? StockType.VN
                                                            : StockType.VD
                                                    );

                                                    dispatch(
                                                        FilterDuck.setFilters(newFilters, geoLocation, distanceRadius)
                                                    );
                                                }}
                                            />
                                            <span className="slider round" />
                                        </label>
                                        <span
                                            className={`switcher-label ${
                                                router?.query[STOCK_TYPE] === StockType.VD ? 'isActive' : ''
                                            }`}
                                        >
                                            {t('filters.demoVehicles')}
                                        </span>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        );
    } catch (e: any) {
        return <HandledComponentError error={e} />;
    }
};

export default FilterBoxTemplate;
