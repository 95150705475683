import styled from 'styled-components';
import { PriceBoxStyled } from '../PriceBoxStyled';
import { rem } from 'polished';

export const PriceBoxStyledAC = styled(PriceBoxStyled)`
    background: ${({ theme }) => theme.colors.grey6} !important;
    border-radius: ${rem(12)};
    margin-top: ${rem(30)};
    position: relative;
    height: calc(100% - 30px);

    .catalogPrice {
        color: ${({ theme }) => theme.colors.grey5};
        line-height: ${({ theme }) => theme.lineHeights.textSupMedium};
        position: absolute;
        height: ${rem(30)};
        width: 100%;
        top: -${rem(30)};

        .catalogPriceLabel {
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
        }
        .catalogPriceAmount {
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
            font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
        }

        .currencyLabel__suffix--OTR  {
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
            font-weight: ${({ theme }) => theme.fontWeights.textBold};
        }
    }

    .demoLabel {
        svg {
            g,
            circle {
                fill: ${({ theme }) => theme.colors.grey2};
                stroke: ${({ theme }) => theme.colors.grey2};
            }

            g,
            line {
                stroke: ${({ theme }) => theme.colors.white};
            }
        }
    }

    .priceBoxWrapper {
        .financeLabel  {
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
            color: ${({ theme }) => theme.colors.grey5};

            .specialPriceLabel {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
            }

            .priceLabel {
                font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
            }
        }

        .aprValue {
            color: ${({ theme }) => theme.colors.red};
        }

        .aprDescription {
            text-decoration: underline;
            margin-top: ${rem(5)};
        }

        .price {
            .cashPrice,
            .monthlyPrice {
                font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};

                span {
                    color: ${({ theme }) => theme.colors.grey5};
                }

                .currency {
                    font-size: ${rem(22)};
                }
            }

            .cashPrice {
                font-size: ${rem(22)} !important;
            }

            .monthlyPrice {
                font-size: ${rem(22)} !important;
            }

            .priceConjunction {
                span {
                    font-size: ${({ theme }) => theme.fontSizes.h6};
                    color: ${({ theme }) => theme.colors.grey5};
                }
            }

            .monthlyPrice {
                .infoIcon {
                    height: ${rem(22)};

                    svg {
                        circle {
                            fill: ${({ theme }) => theme.colors.grey2};
                            stroke: ${({ theme }) => theme.colors.grey2} !important;
                        }

                        line {
                            stroke: ${({ theme }) => theme.colors.white} !important;
                        }
                    }
                }
            }

            .currency,
            .currencyLabel__suffix--OTR,
            .currencyLabel__suffix--TTC {
                font-size: ${({ theme }) => theme.fontSizes.h5};
                font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
            }

            .currencyLabel__suffix--TTC-monthly {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                font-size: ${({ theme }) => theme.fontSizes.textBase};
                text-transform: lowercase;
            }
        }

        .showPriceExcludedVAT-container {
            .currency,
            .cashPrice {
                font-size: ${rem('16px')} !important;
            }

            .currencyLabel__suffix--OTR {
                font-size: ${rem('12px')};
                text-transform: unset;
            }
        }

        .priceNotice {
            font-size: ${({ theme }) => theme.fontSizes.h6};

            .amount {
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                color: ${({ theme }) => theme.colors.grey5};
            }

            .promotionalText {
                color: ${({ theme }) => theme.colors.grey5};
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            }
        }
    }

    .aprDescription {
        color: ${({ theme }) => theme.colors.red};

        &:hover {
            color: ${({ theme }) => theme.colors.grey5};
            text-decoration: none;
        }
    }
`;
