import { breakpoints, css } from '../../styles/theme';
import { rem } from 'polished';

export const StylesAP = css`
    .filters {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        color: ${({ theme }) => theme.colors.black};
    }

    .switcher {
        &-label {
            font-size: ${({ theme }) => theme.fontSizes.textSupBase};
        }
    }
`;
