import styled from 'styled-components';
import { PriceBoxStyled } from '../PriceBoxStyled';
import { rem } from 'polished';

export const PriceBoxStyledAP = styled(PriceBoxStyled)`
    background-color: rgba(236, 236, 238, 0.35);

    .demoLabel {
        svg {
            margin-top: ${rem(-1)};

            g {
                circle,
                line {
                    stroke: ${({ theme }) => theme.colors.primary};
                }
            }
        }
    }
`;
